var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane active",attrs:{"id":"live-stats"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[(_vm.fetched && !_vm.usage.length)?_c('div',{staticClass:"text-center table-placeholder"},[_c('br'),_c('i',{staticClass:"fas fa-tachometer-alt font-size-80"}),_c('h5',{staticClass:"card-title m-t-20"},[_vm._v("Nenhum gasto encontrado")])]):(_vm.fetched)?_c('data-table',{attrs:{"data":_vm.usage,"pages":_vm.pages,"columns":[
          {
            key: 'creation_date',
            filters: ['date|DD/MM/YYYY'],
            label: 'created_at',
          },
          'protocol',
          {
            key: 'channel',
            class: 'badge badge-primary text-white',
          },
          {
            key: 'open_date',
            filters: ['date|HH:mm:ss'],
            label: 'start_hour',
          },
          {
            key: 'close_date',
            filters: ['date|HH:mm:ss'],
            label: 'end_hour',
          },
          'name',
          'tabulation',
          'evaluation',
        ]},on:{"change-page":_vm.preFetchUsage},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showMessages(item)}}},[_c('i',{staticClass:"fas fa-comments"})])]}}])}):_c('div',{staticClass:"loading min-h-300"},[_c('LoadingAnim')],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }